import { Usuario } from '../../classes/usuario';
import { Injectable } from '@angular/core';
import { Permissoes } from '../../classes/permissoes';
import { ArmazenamentoService } from '../armazenamento/armazenamento.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioLogadoService {
  protected usuario: Usuario;
  protected permissoes: Permissoes;
 
  constructor(
    private armazenamentoS: ArmazenamentoService,
  ) {
    this.usuario = new Usuario();
    this.permissoes = new Permissoes();

    this.carregarDadosUsuarioLogado();
    this.configurarPermissoes();
  }

  carregarDadosUsuarioLogado() {
    let usuario = JSON.parse(this.armazenamentoS.obterItem('usuarioAtualPortalDFe'));

    if (usuario) {
      let primeiroNome = usuario.nome.split(' ');
      primeiroNome = primeiroNome[0];

      this.usuario.definirIdUltimoProjetoSelecionado(usuario.idUltimoProjetoSelecionado);
      // this.usuario.definirIdUltimoTpAmbSelecionado(usuario.idUltimoTpAmbSelecionado);
      this.usuario.definirIdUltimoFusoSelecionado(usuario.idUltimoFusoSelecionado);
      this.usuario.definirIdTemaSelecionado(usuario.idTemaSelecionado);
      this.usuario.definirIdUsuario(usuario.idusuario);
      this.usuario.definirFoto(usuario.foto);
      this.usuario.definirModalidade(usuario.modalidade);
      this.usuario.definirNome(usuario.nome);
      this.usuario.definirPrimeiroNome(primeiroNome);
      this.usuario.definirProjetos(usuario.projetos);
      this.usuario.definirToken(usuario.token);
    }
  }

  configurarPermissoes() {
    if (this.usuario.obterModalidade() === 'newsyst') {
      this.permissoes.definirControleNS(true);
      this.permissoes.definirControleSH(true);
      this.permissoes.definirControleEmitente(true);
      this.permissoes.definirControleContador(true);
    
    } else if (this.usuario.obterModalidade() === 'sh') {
      this.permissoes.definirControleNS(false);
      this.permissoes.definirControleSH(true);
      this.permissoes.definirControleEmitente(true);
      this.permissoes.definirControleContador(true);

    } else if (this.usuario.obterModalidade() === 'emit') {
      this.permissoes.definirControleNS(false);
      this.permissoes.definirControleSH(false);
      this.permissoes.definirControleEmitente(true);
      this.permissoes.definirControleContador(false);

    } else if (this.usuario.obterModalidade() === 'cont') {
      this.permissoes.definirControleSH(false);
      this.permissoes.definirControleEmitente(false);
      this.permissoes.definirControleContador(true);
    }
  }

  obterUsuario() {
    return this.usuario;
  }

  obterPermissoes() {
    return this.permissoes;
  }
}
