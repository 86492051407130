import { Injectable } from '@angular/core';
// import { Usuario } from './../../../login/usuario';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../classes/app.config';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    urlPortalApi = AppConfig.obterUrlPortalApi();
    urlGestorApi = AppConfig.obterUrlGestorApi();

    constructor(
        private router: Router,
        private _http: HttpClient
    ) { }

    atualizarSenha(token, senha) {
        let dados = {
            token: token,
            senha: senha
        }

        return this._http.post(this.urlGestorApi + '/v1/Usuarios/atualizarsenha', dados, {
            headers: new HttpHeaders().set('token-portal-api', token)
        });
    }

    login(usuario) {
        let dados = {
            usuario: usuario.login,
            senha: usuario.senha
        };

        return this._http.post(this.urlGestorApi + '/api/v1/login/portal', dados)
    }

    loginRedes(rede, token) {
        const dados = {
            loginSocial: {
                rede: rede,
                token: token
            }
        };

        return this._http.post(this.urlGestorApi + '/api/v1/login/portal', dados);
    }

    logout() {
        sessionStorage.removeItem('isLoggedin');
        sessionStorage.removeItem('usuarioAtualPortalDFe');

        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('usuarioAtualPortalDFe');

        location.reload();
    }

    resetarSenha(login) {
        let dados = {
            login: login
        };

        return this._http.post(this.urlGestorApi + '/v1/login/ResetarSenha', dados, {
            headers: new HttpHeaders().set('token-gestor-api', 'ns1234token')
        });
    }
}
