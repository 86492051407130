import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './shared/guard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoginService } from './shared/services/login/login.service';
import { ArmazenamentoService } from './shared/services/armazenamento/armazenamento.service';
import { AgendamentosGuard } from './shared/guard/agendaments.guard';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GuidedTourModule,
    ToastrModule.forRoot(
      {
        progressBar: true
      }
    )
  ],
  providers: [
    AuthGuard,
    AgendamentosGuard,
    LoginService,
    ArmazenamentoService,
    GuidedTourService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
