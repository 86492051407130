export class Permissoes {
    private controleNS: boolean;
    private controleSH: boolean;
    private controleEmitente: boolean;
    private controleContador: boolean;

    constructor() {
        this.controleNS = false;
        this.controleSH = false;
        this.controleEmitente = false;
        this.controleContador = false;
    }

    obterControleNS() {
        return this.controleNS;
    }

    definirControleNS(controleNS: boolean) {
        this.controleNS = controleNS;
    }

    obterControleSH() {
        return this.controleSH;
    }

    definirControleSH(controleSH: boolean) {
        this.controleSH = controleSH;
    }

    obterControleEmitente() {
        return this.controleEmitente;
    }

    definirControleEmitente(controleEmitente: boolean) {
        this.controleEmitente = controleEmitente;
    }

    obterControleContador() {
        return this.controleContador;
    }

    definirControleContador(controleContador: boolean) {
        this.controleContador = controleContador;
    }
}