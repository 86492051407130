import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/shared/classes/app.config';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    //AppConfig.definirUrlPortalAntigo('https://portal.ns.eti.br');
    //AppConfig.definirUrlPortalAntigoApi('https://portal.ns.eti.br/dfe_portal_server');
    //AppConfig.definirUrlPortalAntigoDownload('https://portal.ns.eti.br/portal_files');
    AppConfig.definirUrlPortalApi('https://portalapi.ns.eti.br');
    AppConfig.definirUrlGestorApi('https://gestorapi.ns.eti.br');
    AppConfig.definirUrlMailTrackApi('https://mailtrack.ns.eti.br');


} else {
    //AppConfig.definirUrlPortalAntigo('https://portalhml.ns.eti.br/antigo');
    //AppConfig.definirUrlPortalAntigoApi('http://52.191.215.1:8011');
    //AppConfig.definirUrlPortalAntigoDownload('http://52.191.215.1:8011/portal_files');
    AppConfig.definirUrlPortalApi('https://portalapihml.ns.eti.br');
    AppConfig.definirUrlGestorApi('https://gestorapihml.ns.eti.br');
    AppConfig.definirUrlMailTrackApi('https://mailtrackhml.ns.eti.br');
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
