import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { ArmazenamentoService } from '../services/armazenamento/armazenamento.service'
import { UsuarioLogadoService } from '../services/usuario-logado/usuario-logado.service'

@Injectable()
export class AgendamentosGuard implements CanActivate {

    constructor(
        private usuarioLogadoS: UsuarioLogadoService,
        private toastrS: ToastrService
    ) { }

    canActivate() {
        if (this.usuarioLogadoS.obterPermissoes().obterControleSH() || this.usuarioLogadoS.obterPermissoes().obterControleEmitente()) {
            return true
        }

        this.toastrS.info('Você não tem permissão para acessar esta funcionalidade')
        return false
    }
}
