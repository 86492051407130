import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
    { 
        path: 'login', 
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    { 
        path: '404', 
        loadChildren: () => import('./404/not-found.module').then(m => m.NotFoundModule)
    },
    { 
        path: '**', 
        redirectTo: '404' 
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash: true}), NgbModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
