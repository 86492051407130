export class AppConfig {
    //private static urlPortalAntigo;
    //private static urlPortalAntigoApi;
    //private static urlPortalAntigoApiDownload;
    // private static urlPortalAntigoDownloadNFCe;
    // private static urlPortalAntigoDownloadDocs;

    private static urlPortalApi;
    private static urlGestorApi;
    private static urlMailTrackApi;

    /*
    public static obterUrlPortalAntigo() {
        return this.urlPortalAntigo;
    }

    public static definirUrlPortalAntigo(url) {
        return this.urlPortalAntigo = url;
    }
    */

    /*
    public static obterUrlPortalAntigoApi() {
        return this.urlPortalAntigoApi;
    }

    public static definirUrlPortalAntigoApi(url) {
        this.urlPortalAntigoApi = url;
    }
    */

    /*

    public static obterUrlPortalAntigoDownload() {
        return this.urlPortalAntigoApiDownload;
    }

    public static definirUrlPortalAntigoDownload(url) {
        this.urlPortalAntigoApiDownload = url;
    }
    */

    // public static obterUrlPortalAntigoDownloadNFCe() {
    //     return this.urlPortalAntigoDownloadNFCe;
    // }

    // public static definirUrlPortalAntigoDownloadNFCe(url) {
    //     this.urlPortalAntigoDownloadNFCe = url;
    // }

    // public static obterUrlPortalAntigoDownloadDocs() {
    //     return this.urlPortalAntigoDownloadDocs;
    // }

    // public static definirUrlPortalAntigoDownloadDocs(url) {
    //     this.urlPortalAntigoDownloadDocs = url;
    // }

    public static obterUrlPortalApi() {
        return this.urlPortalApi;
    }

    public static definirUrlPortalApi(url) {
        this.urlPortalApi = url;
    }

    public static obterUrlGestorApi() {
        return this.urlGestorApi;
    }

    public static definirUrlGestorApi(url) {
        this.urlGestorApi = url;
    }


    public static obterUrlMailTrackApi() {
        return this.urlMailTrackApi;
    }

    public static definirUrlMailTrackApi(url) {
        this.urlMailTrackApi = url;
    }
}