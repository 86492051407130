import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { ArmazenamentoService } from '../services/armazenamento/armazenamento.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private armazenamentoS: ArmazenamentoService,
        private router: Router
    ) { }

    canActivate() {
        if (this.usuarioEstaLogado()) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }

    usuarioEstaLogado() {
        let token;

        try {
            let usuarioAtualPortalDFe = JSON.parse(this.armazenamentoS.obterItem('usuarioAtualPortalDFe'));
            token = usuarioAtualPortalDFe['token'];
        } catch (error) {
            token = '';
        }

        return (this.armazenamentoS.obterItem('isLoggedin') == 'true' && token != '');
    }
}
