export class Usuario {
    private idUltimoProjetoSelecionado: number;
    // private idUltimoTpAmbSelecionado: number;
    private idUltimoFusoSelecionado: number;
    private idTemaSelecionado: number;
    private idusuario: any;
    private foto: any;
    private modalidade: any;
    private nome: any;
    private primeiroNome: any;
    private projetos: any[];
    private token: any;

    constructor() { 
        this.idusuario = null;
        this.foto = null;
        this.modalidade = null;
        this.nome = null;
        this.primeiroNome = null;
        this.projetos = null;
        this.token = null;
        this.idUltimoProjetoSelecionado = null;
    }

    obterIdUltimoProjetoSelecionado() {
        return this.idUltimoProjetoSelecionado;
    }

    definirIdUltimoProjetoSelecionado(idUltimoProjetoSelecionado: number) {
        this.idUltimoProjetoSelecionado = idUltimoProjetoSelecionado;
    }

    // obterIdUltimoTpAmbSelecionado() {
    //     return this.idUltimoTpAmbSelecionado;
    // }

    // definirIdUltimoTpAmbSelecionado(idUltimoTpAmbSelecionado: number) {
    //     this.idUltimoTpAmbSelecionado = idUltimoTpAmbSelecionado;
    // }

    obterIdUltimoFusoSelecionado() {
        return this.idUltimoFusoSelecionado;
    }

    definirIdUltimoFusoSelecionado(idUltimoFusoSelecionado: number) {
        this.idUltimoFusoSelecionado = idUltimoFusoSelecionado;
    }

    obterIdTemaSelecionado() {
        return this.idTemaSelecionado;
    }

    definirIdTemaSelecionado(idTemaSelecionado: number) {
        this.idTemaSelecionado = idTemaSelecionado;
    }

    obterIdUsuario() {
        return this.idusuario;
    }

    definirIdUsuario(idusuario) {
        this.idusuario = idusuario;
    }

    obterFoto() {
        return this.foto;
    }
    
    definirFoto(foto) {
        this.foto = foto;
    }
    
    obterModalidade() {
        return this.modalidade;
    }

    definirModalidade(modalidade) {
        this.modalidade = modalidade;
    }

    obterNome() {
        return this.nome;
    }

    definirNome(nome) {
        this.nome = nome;
    }

    obterPrimeiroNome() {
        return this.primeiroNome;
    }

    definirPrimeiroNome(primeiroNome) {
        this.primeiroNome = primeiroNome;
    }

    obterProjetos() {
        return this.projetos;
    }

    definirProjetos(projetos) {
        this.projetos = projetos;
    }

    obterToken() {
        return this.token;
    }

    definirToken(token) {
        this.token = token;
    }
}
